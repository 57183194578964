import { createApp, markRaw } from 'vue'
import { setupRouter } from './router'
import { createPinia } from 'pinia'
import App from './App.vue'

require('./assets/css/style.css')

const router = setupRouter()
const pinia = createPinia()

// pinia.use(({ store }) => {
//   store.router = markRaw(router)
// })

createApp(App).use(router).use(pinia).mount('#app')
